(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/event-list/assets/javascripts/event-list-item.js') >= 0) return;  svs.modules.push('/components/marketplace/event-list/assets/javascripts/event-list-item.js');
"use strict";


const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const EventListItem = _ref => {
  let {
    eventNumber,
    eventDescription,
    eventComment,
    matchStart
  } = _ref;
  return React.createElement("li", null, React.createElement("div", {
    className: "event-list-row"
  }, React.createElement("div", {
    className: "event-number"
  }, eventNumber), React.createElement("div", {
    className: "event-description notranslate"
  }, React.createElement("p", null, eventDescription), React.createElement("p", {
    className: "event-description-extra"
  }, eventComment)), React.createElement("div", {
    className: "fc-grey-400 event-date"
  }, React.createElement("p", null, formatDate(new Date(matchStart), true, true, true)))));
};
setGlobal('svs.components.marketplace.eventList.EventListItem', EventListItem);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/event-list/assets/javascripts/event-list.js') >= 0) return;  svs.modules.push('/components/marketplace/event-list/assets/javascripts/event-list.js');
"use strict";


const {
  EventListItem
} = svs.components.marketplace.eventList;
const EventList = _ref => {
  let {
    events,
    warningMessage,
    isOneMatchGame,
    oneGameMatchDescription
  } = _ref;
  return React.createElement("div", {
    className: "event-list-container margin-bottom-2"
  }, warningMessage ? React.createElement("div", {
    className: "align-center"
  }, React.createElement("h3", {
    className: "f-content f-700 f-medium margin-bottom-1"
  }, "Radskiss"), React.createElement("span", {
    className: "fc-grey-500"
  }, warningMessage)) : null, events.length > 0 && React.createElement("div", {
    className: "event-list"
  }, React.createElement("ul", {
    className: "list-group event-list-component"
  }, React.createElement("li", null, React.createElement("div", {
    className: "event-list-row event-list-row-header"
  }, React.createElement("div", {
    className: "event-description fc-grey-400 f-500 f-uppercase"
  }, "MATCHER"), React.createElement("div", {
    className: "event-date fc-grey-400 f-500 f-uppercase "
  }, "START")), isOneMatchGame ? React.createElement("div", {
    className: "padding-xs"
  }, oneGameMatchDescription) : null), events.map(event => React.createElement(EventListItem, {
    eventComment: event.eventComment,
    eventDescription: event.eventDescription,
    eventNumber: event.eventNumber,
    key: event.eventNumber,
    matchParticipants: event.matchParticipants,
    matchStart: event.matchStart
  })))));
};
EventList.defaultProps = {
  events: []
};
setGlobal('svs.components.marketplace.eventList.EventList', EventList);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/event-list/assets/javascripts/event-list-container.js') >= 0) return;  svs.modules.push('/components/marketplace/event-list/assets/javascripts/event-list-container.js');
"use strict";


const {
  selectors
} = svs.components.marketplaceData;
const {
  EventList
} = svs.components.marketplace.eventList;
const mapStateToProps = (state, _ref) => {
  let {
    groupId,
    activatedDrawId,
    drawNumber,
    productName
  } = _ref;
  const currentGame = groupId && activatedDrawId ? selectors.games.getGame(state, {
    activatedDrawId,
    groupId
  }) : undefined;
  const currentDrawNumber = currentGame && currentGame.drawNumber ? currentGame.drawNumber : drawNumber || undefined;
  const currentProductName = currentGame && currentGame.productName ? currentGame.productName : productName || undefined;
  const currentDraw = currentDrawNumber ? selectors.draws.getDraw(state, {
    drawNumber: currentDrawNumber,
    productName: currentProductName
  }) : undefined;
  const drawEvents = currentDraw ? selectors.draws.getDrawEvents(state, {
    drawNumber: currentDrawNumber,
    productName: currentProductName
  }) : [];
  const isOneMatchGame = currentDraw && currentDraw.matchCount ? currentDraw.matchCount === 1 : false;
  const oneGameMatchDescription = isOneMatchGame ? "".concat(drawEvents[0].matchParticipants[0].mediumName, " - ").concat(drawEvents[0].matchParticipants[1].mediumName) : undefined;
  return {
    events: drawEvents,
    isOneMatchGame,
    oneGameMatchDescription
  };
};
setGlobal('svs.components.marketplace.eventList.EventListContainer', ReactRedux.connect(mapStateToProps)(EventList));

 })(window);